import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import XSRE from './XSRE';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <XSRE
	  dealer="XSRE Demo Dealer"
	  vin="21312341x3"
	  make="Jeep"
	  model="Wrangler 4xe"
	  year="2023"
	  authToken="eyJhbGciOiJIUzI1NiJ9.IjEi.4AZoswRMyYlzDPD90W3IElxEyhKx9pKspEJNeFgRnzU"
	  modalImageUrl="https://storage.googleapis.com/xsre-hines/home/jeep_wrangler.jpeg"
	
	 />
  </React.StrictMode>,
  document.getElementById('xsre')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
