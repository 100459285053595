import { React, Component } from "react";
import Slider from "react-slick";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class XSRE extends Component {
  
  constructor(props){
    super(props);
    this.state = {
	  items: [],
	  selected_items:[],
	  first_name:"",
	  last_name:"",
	  phone:"",
	  email:"",
	  errors:{},
	  isSubmitting:false,
	  isSent:false,
	  show:false,
	  demo_model:1
    };
	
  this.handleToggle = this.handleToggle.bind(this);
  this.handleTextInput = this.handleTextInput.bind(this);
  this.submitLead = this.submitLead.bind(this);
  this.handleModalClose = this.handleModalClose.bind(this);
  this.handleModalOpen = this.handleModalOpen.bind(this);
  this.handleSlideChange = this.handleSlideChange.bind(this);
  }
  
  componentDidMount(){
	  this.getSlides()
  }
  
  handleSlideChange(event){
	  //clear the existing slides
	  
	  //reget getSlides
	  this.setState({ selected_items: [] })
	  this.setState({ items: [] })
	  this.getSlides(event.target.value)
	  
  }
  
  getSlides(model_id = 1){
	  
	  fetch(`/api/v1/models/${model_id}/items.json`, 
		  {method: 'get',
	  headers: {
			  "Authorization": `Bearer ${this.props.authToken}`
	  }
  })
	      .then(response => {
	          return response.json(); 
	      })
	      .then(json => {
			  const final = json.map(v => ({...v, checked: false}))	
			  console.log('parsed json ', final);
	          this.setState({items: final});
	      })
	      .catch(error => {
	          console.log(error);
	      });
	
  }
  
  submitInternal(){
		let string_of_accessories = ""
		this.state.selected_items.forEach(function (item) {
		 string_of_accessories = string_of_accessories + item.sku.toString() + "|"
		});
		string_of_accessories = string_of_accessories.slice(0, -1)

		const jsonBody = { 
			lead:{
				 vin:this.props.vin,
				 make:this.props.make,
				 model:this.props.model,
				 year:this.props.year,
				 items: string_of_accessories
			 }
		}
		fetch( `/api/v1/leads`,
		{
		method: 'post',
		body: JSON.stringify(jsonBody),
		headers:  {
		      "Content-Type": "application/json",
		      "Accept": "application/json",
			  "Authorization": `Bearer eyJhbGciOiJIUzI1NiJ9.IjEi.4AZoswRMyYlzDPD90W3IElxEyhKx9pKspEJNeFgRnzU`
		    }
		})
		.then(response => {	 
		  return response.json();
		})
		.then(json => {
			console.log("response", json)
		})
		.catch(error => {
			debugger;
			console.log(error);
		});
  }
  
  submitExternal(){ 
	  //send xml
	  //email
	  //send json
  }
  
  handleToggle(event){
	  var add_or_remove = event.target.checked
	  var sku = event.target.name
	  if (add_or_remove === true  ){
		
		  		
		var item_array = this.state.items
		var i_index = item_array.findIndex(i => i.sku == sku)
		if (i_index != -1){
			item_array[i_index].checked=true
		}
		var item = item_array[i_index]
		var selected_items_array = this.state.selected_items
		selected_items_array.push(item)
	  	this.setState({
		  items:item_array,
		  selected_items:selected_items_array
	  	})
 	  }
 	  else if (add_or_remove === false ) {
   		
		var item_array = this.state.items
		var selected_items_array = this.state.selected_items	
		var i_index = item_array.findIndex(i => i.sku == sku);
   		
		if (i_index != -1){
   			item_array[i_index].checked=false
   		}
		
		var si_index = selected_items_array.findIndex(i => i.sku == sku)
		if (si_index != -1){
			selected_items_array.splice(si_index,1)
		}
		
   		this.setState({
   			items:item_array,
			selected_items:selected_items_array
 
   		})
 	  }
	  if (event.target.parentElement.classList[1] == "mainSliderInput") {
	  	this.handleModalOpen()
	  }
  }
  
  submitLead(event){
	  event.preventDefault()
	  let newErrors = this.findFormErrors()
	  if ( Object.keys(newErrors).length > 0 ) {
	        // We got errors!
	        this.setState({errors: newErrors})
	      } else {
   	          // No errors! Put any logic here for the form submission!
			  this.setState({isSubmitting:true})	
			  this.setState({errors: newErrors})
			  //generate and post lead back to our system
			  this.submitInternal()
			  //generate and post xml lead 
			  this.submitExternal()
			  // *******
			  //fake api wait right now to get the loading interaction right
			  // *******
			  setTimeout(()=>{
				  this.setState({isSubmitting:false})
				  this.setState({isSent:true})
			  }, 2000)	
	      }
  }
  
  handleModalClose() {
	  this.setState({show:false})
	  setTimeout(()=>{
		  this.setState({isSent: false})
	  }, 500)	
	  
  	
  }
  
  handleModalOpen() {
	  this.setState({show:true})
  	
  }
  
  emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  
  phoneIsValid (phone) {
   return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone)
  }
  
  findFormErrors() {
      const first_name = this.state.first_name
	  const last_name = this.state.last_name
	  const phone = this.state.phone
	  const email = this.state.email
      const newErrors = {}
      // check for errors
      if ( !first_name  ) newErrors.first_name = 'Cannot Be Blank'
	  if ( !last_name  ) newErrors.last_name = 'Cannot Be Blank'
	  if ( !this.emailIsValid(email) && email) newErrors.email = 'Must be Valid Email'
	  if ( !this.phoneIsValid(phone) && phone) newErrors.phone = 'Must be Valid Phone Number'
	  if ( !phone && !email ) {newErrors.phone = 'Phone Or Email Required'; newErrors.email = 'Phone Or Email Required';}
      return newErrors
	  //add errors object to state
  }
  
  handleTextInput(e){
	  this.setState({[e.target.name]: e.target.value})
  }

  render() {
    const settings = {
	 	       dots: true,
			   infinite:true,
		 	   speed: 300,
			   slidesToShow: 3
			 	    }	
			
	
    return (
	
	<div>
		

		<div className="xsreButtonRow text-center">
			<Button onClick={this.handleSlideChange} variant="primary" value="1" >Default Set</Button>{' '}
			<Button onClick={this.handleSlideChange} variant="primary" value="2">Winter Gear</Button>{' '}
			<Button onClick={this.handleSlideChange} variant="primary" value="3">Watersports Racks </Button>{' '}
		</div>
		<div className="xsre text-center">
		<h5>
		 Frequently Bought With
		</h5>
		      <Slider {...settings}>
				{
					this.state.items.map(function(item) {
          			  return (
			            <div className="hines" key={item.sku} id={item.sku}><div className="sliderbg" style={{backgroundImage: `url(${item.image_url})`}}></div>
						  <table>
						  	<tbody>
						  	<tr>
  						        <td>
								  <label className="switch mainSliderInput">
								  <input id="SelectedXSRE_switch" type="checkbox"
								  checked={item.checked} 
								  onChange={this.handleToggle}
								  name={item.sku}
								  />
								  <span data-toggle="modal" 
								  data-target="#largeModal" className="toggler round"></span></label>
						        </td>
						        <td>
						         <h3>{item.title}</h3> 	
						        </td>
						       </tr>
						     </tbody>
						  </table>
						  
						  </div>
          			  	);
        			}, this)
				}
	
		     </Slider>
		</div>
				
			<Modal
				show={this.state.show}
			    onHide={this.handleModalClose}
			    backdrop="static"
				size="xl"
			    keyboard={false}
				>
						
		  <Modal.Header closeButton>
				<h4>{this.props.dealer}</h4>
		  </Modal.Header>

		  <Modal.Body>
				{ this.state.isSent && 
					        <div className="modal-body btn-success">
								Thank you for your submission - come in or someone will be in touch shortly!
							</div>
				}
		
				{ !this.state.isSent && 

		  <div className="row">
		  <div className="col-xl-6">
			  <img id="xsre_hero" src={this.props.modalImageUrl} />
			  <div className="modalSlider">
		      <Slider {...settings}>
				{
					this.state.items.map(function(item) {
		      			  return (
			            <div className="hines" key={item.sku} id={item.sku}><div className="sliderbg" style={{backgroundImage: `url(${item.image_url})`}}></div>
					  <table>
					  	<tbody>
					  	<tr>
					        <td>
							  <div className="switchContainer">
								  <label className="switch">
									  <input id="SelectedXSRE_switch" type="checkbox"
									  checked={item.checked} 
									  onChange={this.handleToggle}
									  name={item.sku}
									  />
									  <span className="toggler round"></span>
								  </label>
								</div>
					        </td>
					        <td>
					         <h3>{item.title}</h3> 	
					        </td>
					       </tr>
					     </tbody>
					  </table>
						  </div>
		      			  	);
		    			}, this)
				}

		     </Slider>
			</div>
		  </div>  
		  <div className="col-xl-6 selections">
			  <div className="xsre_list_container">
				  <table className="table_xsre table">
				  </table>
				  <div className="row">
						  <div className="col-xl-12">
							<h3>{ this.props.year } { this.props.make } { this.props.model }</h3>
							<h5>VIN: { this.props.vin }</h5>
		
						<table className="table xsres_modal">
							<thead>
							<tr>
							<th>Accessory</th>
							<th>SKU</th>
							</tr>
							</thead>
							<tbody>
			
						{
								this.state.selected_items.map(function(item) {
						
								return (
									<tr key={item.sku}><td>{item.title}</td><td>{item.sku}</td></tr>)
							}, this)
						}
							</tbody>
						</table>
					
					
						  </div>
						  <div className="col-xl-12 leadform">
					
							<Form>
								<div className="row">
								<div className="col-xl-6">
									<Form.Group>
							          <Form.Control value={this.state.first_name} isInvalid={ !!this.state.errors.first_name } onChange={this.handleTextInput} type='text' placeholder="First Name" name="first_name"/>
									  <Form.Control.Feedback type='invalid'>
									        { this.state.errors.first_name }
									    </Form.Control.Feedback>
							        </Form.Group>
									<Form.Group>
							          <Form.Control value={this.state.last_name} isInvalid={ !!this.state.errors.last_name } onChange={this.handleTextInput} type='text' placeholder="Last Name" name="last_name" />
									  <Form.Control.Feedback type='invalid'>
									        { this.state.errors.last_name }
									    </Form.Control.Feedback>
							        </Form.Group>
							    </div>
								<div className="col-xl-6">
									<Form.Group>
							          <Form.Control value={this.state.email} isInvalid={ !!this.state.errors.email } onChange={this.handleTextInput} type='text' placeholder="Email" name="email"/>
									  <Form.Control.Feedback type='invalid'>
									        { this.state.errors.email }
									    </Form.Control.Feedback>
							        </Form.Group>
									<Form.Group>
							          <Form.Control value={this.state.phone} isInvalid={ !!this.state.errors.phone } onChange={this.handleTextInput} type='text' placeholder="Phone" name="phone"/>
									  <Form.Control.Feedback type='invalid'>
									        { this.state.errors.phone }
									    </Form.Control.Feedback>
							        </Form.Group>
								</div>
							    </div>
							</Form>
				
				
									
								   						<button type="submit"  disabled={this.state.isSubmitting || this.state.isSent} onClick={this.submitLead} className={`btn btn-large col-xl-12 ${this.state.isSent ? "btn-success" : " btn-primary"}`}>
										{this.state.isSubmitting && <span><i className="fa fa-spinner fa-spin"></i></span>}
														Lets Talk</button>
										{this.state.isSent && 
											<div>
												Thank you - someone will be in touch with you shortly! 
											</div>
										}
										   </div>
										 </div>
									  </div>
								  </div>
								</div>
									}
		
		
					  </Modal.Body>

					  <Modal.Footer>
					  						  <div className="col-xl-12 fineprint">
												Accessory prices do not include sales tax or installation. Consult with your local dealer. Proper installation to be confirmed by dealership.
												By submitting your contact information, you consent to be contacted by telephone about purchasing a vehicle or obtaining vehicle financing. Clicking on the Submit button above is your electronic signature.
					  						  </div>
					  </Modal.Footer> 
					</Modal>
				
				
      </div>
   
    );
  }
 
}